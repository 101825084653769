const imgElements = document.querySelectorAll('.js-lightbox-img');
const lightbox = document.querySelector('.js-lightbox');
const fullImage = lightbox.querySelector('.js-full-image');

for (let i = 0; i < imgElements.length; i++) {
  imgElements[i].addEventListener('click', function () {
    fullImage.src = this.src;
    lightbox.style.display = 'block';
  });
}

lightbox.addEventListener('click', () => {
  fullImage.src = '';
  lightbox.style.display = 'none';
});
