import { subscribe, isSupported } from 'on-screen-keyboard-detector';
import { emitter } from '@/helpers/emitter';
import { scrollTo } from '@/helpers/smoothScroll';

if (isSupported()) {
  subscribe((visibility) =>
    emitter.emit('onScreenKeyboard:change', visibility)
  );
}

export function scrollOnKeyboardAppearance(
  visibility,
  activeInput,
  scrollToNode
) {
  if (visibility === 'visible' && document.activeElement === activeInput) {
    scrollTo(scrollToNode);
  }
}
