export function sleep(ms) {
  return new Promise((resolve) => setTimeout(resolve, ms));
}

export function loadSvg(url, container = null) {
  const req = new XMLHttpRequest();

  req.onload = function (e) {
    let svg = document.implementation.createHTMLDocument('');
    svg.body.innerHTML = e.target.response;

    // Check if SVG already exists in container and
    // if exists, remove it to avoid including the SVG multiple times
    if (container && container.querySelector('svg')) {
      container.removeChild(container.querySelector('svg'));
    }

    if (!container) {
      container = document.createElement('div');
      container.style.position = 'absolute';
      container.style.width = 0;
      container.style.height = 0;
      container.style.overflow = 'hidden';
      container.style.zIndex = -9999;
      document.body.appendChild(container);
    }

    container.appendChild(svg.body.firstElementChild);
    svg = null;
  };

  req.open('GET', url, true);
  req.responseType = 'text';
  req.send();
}

export function detectTouchScreen() {
  let hasTouchScreen = false;

  if ('maxTouchPoints' in navigator) {
    hasTouchScreen = navigator.maxTouchPoints > 0;
  }

  return hasTouchScreen;
}

export function vimeoPlaceholderClickHandler(event) {
  const target = event.currentTarget;

  target.removeEventListener('click', vimeoPlaceholderClickHandler);
  delete target.dataset.placeholder;

  const url = `https://player.vimeo.com/video/${target.dataset.video}`;
  const muted = detectTouchScreen() ? '&muted=1' : '';

  target.innerHTML = `<iframe class="js-success-video-object" src="${url}?autoplay=1${muted}" allow="autoplay; fullscreen" width="100%" height="100%" frameborder="0" webkitallowfullscreen mozallowfullscreen allowfullscreen></iframe>`;

  const iframe = document.querySelector('.js-success-video-object');
  iframe.onload = () => {
    iframe.classList.add('height');
  }
}

export function getDeviceWidth() {
  return window.innerWidth > 0 ? window.innerWidth : window.screen.width;
}

export function loadScript(url, onload) {
  const script = document.createElement('script');
  script.type = 'text/javascript';
  script.async = true;

  if (onload) {
    script.onload = onload;
  }

  script.src = url;
  document.head.appendChild(script);
}

export function loadStylesheet(url, onload) {
  const link = document.createElement('link');
  link.rel = 'stylesheet';

  if (onload) {
    link.onload = onload;
  }

  link.href = url;
  document.head.appendChild(link);
}

export function slideUp (target, duration=500)  {
  target.style.transitionProperty = 'height, margin, padding';
  target.style.transitionDuration = duration + 'ms';
  target.style.boxSizing = 'border-box';
  target.style.height = target.offsetHeight + 'px';
  target.offsetHeight;
  target.style.overflow = 'hidden';
  target.style.height = 0;
  target.style.paddingTop = 0;
  target.style.paddingBottom = 0;
  target.style.marginTop = 0;
  target.style.marginBottom = 0;
  window.setTimeout( () => {
    target.style.display = 'none';
    target.style.removeProperty('height');
    target.style.removeProperty('padding-top');
    target.style.removeProperty('padding-bottom');
    target.style.removeProperty('margin-top');
    target.style.removeProperty('margin-bottom');
    target.style.removeProperty('overflow');
    target.style.removeProperty('transition-duration');
    target.style.removeProperty('transition-property');
    //alert("!");
  }, duration);
}

export function slideDown (target, duration=500)  {
  target.style.removeProperty('display');
  let display = window.getComputedStyle(target).display;

  if (display === 'none')
    display = 'block';

  target.style.display = display;
  let height = target.offsetHeight;
  target.style.overflow = 'hidden';
  target.style.height = 0;
  target.style.paddingTop = 0;
  target.style.paddingBottom = 0;
  target.style.marginTop = 0;
  target.style.marginBottom = 0;
  target.offsetHeight;
  target.style.boxSizing = 'border-box';
  target.style.transitionProperty = "height, margin, padding";
  target.style.transitionDuration = duration + 'ms';
  target.style.height = height + 'px';
  target.style.removeProperty('padding-top');
  target.style.removeProperty('padding-bottom');
  target.style.removeProperty('margin-top');
  target.style.removeProperty('margin-bottom');
  window.setTimeout( () => {
    target.style.removeProperty('height');
    target.style.removeProperty('overflow');
    target.style.removeProperty('transition-duration');
    target.style.removeProperty('transition-property');
  }, duration);
}
export function slideToggle (target, duration = 500) {
  if (window.getComputedStyle(target).display === 'none') {
    return slideDown(target, duration);
  } else {
    return slideUp(target, duration);
  }
}

