import smoothscroll from 'smoothscroll-polyfill'; // http://iamdustan.com/smoothscroll/

// kick off the polyfill!

smoothscroll.polyfill();

// attach move down buttons

document.querySelectorAll('.js-scroll-next').forEach((el) =>
  el.addEventListener('click', () => {
    const destinationNode = el.closest('section').nextElementSibling;

    scrollTo(destinationNode);
  })
);

document.querySelectorAll('.js-scroll-to').forEach((el) => {
  el.addEventListener('click', (event) => {
    event.preventDefault();
    const destinationNode = document.querySelector(
      `[data-scroll-anchor="${el.dataset.scrollDestination}"]`
    );

    scrollTo(destinationNode);
  });
});

const getHeaderOffset = () => {
  let result = 0;

  const nodes = document.querySelectorAll('.js-header-offset-node');
  nodes.forEach((node) => {
    result += node.offsetHeight;
  });

  return result;
};

const getNodeTopFromStart = (node) => {
  const rect = node.getBoundingClientRect();
  return rect.top + (window.scrollY || 0);
};

export const scrollTo = (node) => {
  if (node) {
    const topOfNode = getNodeTopFromStart(node) - getHeaderOffset();
    window.scroll({ top: topOfNode, behavior: 'smooth' });
  }
};
